import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons/faWhatsapp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { publicationbg } from "../Assets";

const SinglePublicationPage = () => {
  const { id } = useParams(); // Get the publication ID from the URL
  const [publication, setPublication] = useState(null);
  const [latestPublications, setLatestPublications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch publication data and latest publications
  useEffect(() => {
    const fetchPublication = async () => {
      try {
        const response = await fetch(`/api/get_publications.php?id=${id}`);
        const data = await response.json();

        // Check if the data returned is valid and not empty
        if (data && data.data && data.data.length > 0) {
          setPublication(data.data[0]); // Assuming single publication returned in 'data' array
        } else {
          console.error("Publication not found");
        }
      } catch (error) {
        console.error("Error fetching publication:", error);
      }
    };

    const fetchLatestPublications = async () => {
      try {
        const response = await fetch("/api/get_publications.php?limit=3"); // Get latest 3 publications
        const data = await response.json();

        if (data && data.data) {
          setLatestPublications(
            data.data.filter((publication) => publication.id !== parseInt(id)) // Exclude current publication
          );
        }
      } catch (error) {
        console.error("Error fetching latest publications:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPublication();
    fetchLatestPublications();
  }, [id]);

  // Format the date to "Month Day, Year"
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!publication) {
    return <div>Publication not found.</div>;
  }

  // Social media share URL formats
  const shareUrls = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`,
    twitter: `https://twitter.com/intent/tweet?url=${window.location.href}&text=${publication.title}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${publication.title}`,
    whatsapp: `https://wa.me/?text=${encodeURIComponent(
      `${publication.title} ${window.location.href}`
    )}`,
  };

  return (
    <div className="pb-24">
      {/* Hero Section with new background */}
      <div
        className="relative bg-cover bg-center h-96 w-full"
        style={{
          backgroundImage: `url(${publicationbg})`, // Set publicationbg as the hero background
        }}
      >
        <div className="absolute inset-0  flex flex-col justify-center items-center text-center">
          <h1 className="text-4xl font-bold text-[#2F3545]">Publications</h1>
        </div>
      </div>

      {/* Main Content Section - Rounded Box */}
      <div className="px-8 md:px-16 lg:px-32 mt-[-80px] z-10 relative">
        <div className="bg-white rounded-2xl border-2 border-[#C1A17C] shadow-lg">
          {/* Publication Image and Title */}
          <div className="relative mb-6">
            {/* Image with gradient overlay */}
            <div
              className="relative w-full h-64 rounded-2xl"
              style={{
                backgroundImage: `url(/api/${publication.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                clipPath: "border-box", // Ensure the image fills the full space
              }}
            >
              {/* Gradient Overlay */}
              <div className="absolute inset-0 bg-black/50" />
            </div>

            {/* Title and Author */}
            <div className="absolute bottom-4 left-4 px-6 py-2 text-white  w-full">
              <h2 className="text-left text-3xl font-semibold">{publication.title}</h2>
              <p className="text-left text-base mt-1 text-white">
                By Bolanle Opadokun
              </p>
            </div>
          </div>
          {/* Publication Content */}
          <div
            className=" text-[#2F3545] leading-relaxed mb-6 p-6"
            dangerouslySetInnerHTML={{ __html: publication.content }} // Render HTML content safely
          />

          {/* Date & Share Section */}
          <div className="flex flex-col md:flex-row  md:justify-between md:items-center p-6">
            <span className="text-sm text-gray-600 mb-2">
              {formatDate(publication.datePublished)}
            </span>

            <div className="">
              <p className="text-lg font-semibold text-[#2F3545] mb-2">
                Share:
              </p>

              {/* Share Buttons */}
              <div className="flex space-x-6">
                <a
                  href={shareUrls.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-[#2F3545] hover:text-[#2F3545] transition"
                >
                  <FontAwesomeIcon icon={faFacebook} size="xl" />
                </a>
                <a
                  href={shareUrls.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-[#2F3545] hover:text-[#2F3545] transition"
                >
                  <FontAwesomeIcon icon={faTwitter} size="xl" />
                </a>
                <a
                  href={shareUrls.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-[#2F3545] hover:text-[#2F3545] transition"
                >
                  <FontAwesomeIcon icon={faLinkedin} size="xl" />
                </a>
                <a
                  href={shareUrls.whatsapp}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center text-[#2F3545] hover:text-[#2F3545] transition"
                >
                  <FontAwesomeIcon icon={faWhatsapp} size="xl" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Latest Publications Section */}
      <div className="px-8 md:px-16 lg:px-32 mt-16">
        <h2 className="text-2xl font-semibold text-[#2F3545] mb-6">
          Latest Publications
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {latestPublications.map((publication) => (
            <div
              key={publication.id}
              className="bg-white rounded-lg border border-[#C1A17C] overflow-hidden shadow hover:shadow-lg transition-shadow duration-300"
            >
              <img
                src={`/api/${publication.image}`}
                alt={publication.title}
                className="w-full h-48 object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="text-xl font-semibold text-[#2F3545] mb-2">
                  {publication.title}
                </h3>
                <div
                  className="text-sm text-gray-700 mb-4"
                  dangerouslySetInnerHTML={{
                    __html: publication.content.substring(0, 100),
                  }} // Render HTML content safely
                />
                <div className="flex items-center justify-between">
                  <Link
                    to={`/publication/${publication.id}`}
                    className="bg-[#C1A17C] text-[#2F3545]  py-2 px-4 rounded-md hover:bg-opacity-90"
                  >
                    Read More
                  </Link>
                  <span className="text-xs text-gray-500">
                    {formatDate(publication.datePublished)}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SinglePublicationPage;