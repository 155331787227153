import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { FloatButton } from "antd";
import { Footer, Navbar, ProtectedRoute } from "./Components";
import {
  About,
  Contact,
  CreatePublication,
  Dashboard,
  DisclaimerPage,
  EditPublication,
  FAQPage,
  Home,
  Login,
  NoPage,
  PrivacyPolicyPage,
  Publications,
  Services,
  SignUp,
  SinglePublicationPage,
  UpdatePassword,
} from "./Pages";

function App() {
  const isAuthenticated = localStorage.getItem("isLoggedIn") === "true"; // Adjust as necessary

  return (
    <div className="App scroll-smooth font-inter">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/*" element={<NoPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/publications" element={<Publications />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/update-password" element={<UpdatePassword />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute
                isAuthenticated={isAuthenticated}
                element={<Dashboard />}
              />
            }
          />
          <Route
            path="/create-publication"
            element={
              <ProtectedRoute
                isAuthenticated={isAuthenticated}
                element={<CreatePublication />}
              />
            }
          />
          <Route
            path="/edit-publication"
            element={
              <ProtectedRoute
                isAuthenticated={isAuthenticated}
                element={<EditPublication />}
              />
            }
          />
          <Route path="/publication/:id" element={<SinglePublicationPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/disclaimer" element={<DisclaimerPage />} />
          <Route path="/faq" element={<FAQPage />} />
        </Routes>
        <Footer />
      </BrowserRouter>

      <FloatButton.BackTop />
    </div>
  );
}

export default App;
