import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contactbg } from '../../Assets';
import { useNavigate } from 'react-router-dom'; // Import for navigation

const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // For password visibility
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch('/api/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      console.log('API Response:', result);

      if (response.ok && result.status === 'success') {
        // Show toast for successful login
        toast.success(result.message || "Login successful!");

        // Store login status in localStorage
        localStorage.setItem('isLoggedIn', 'true');
        setIsLoggedIn(true); // Update login status to trigger redirect

        // After 3 seconds, redirect to /dashboard
        setTimeout(() => {
          navigate('/dashboard');
        }, 3000); // 3-second delay before redirect

      } else {
        toast.error(result.message || "Login failed. Please try again.");
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error('There was a problem logging in.');
    } finally {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className="relative bg-cover bg-top min-h-screen flex items-center justify-center"
      style={{ backgroundImage: `url(${contactbg})` }}
    >
      <div className="relative z-10 w-full max-w-md bg-white shadow-lg rounded-lg p-8">
        <h1 className="text-3xl font-bold text-center text-[#2F3545] mb-6">Admin Login</h1>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col mb-4">
            <label htmlFor="username" className="mb-2 text-sm font-medium text-[#2F3545]">Username</label>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              placeholder="Enter your username"
              className="border border-lightgray p-3 rounded-md focus:outline-none"
            />
          </div>
          <div className="flex flex-col mb-6 relative">
            <label htmlFor="password" className="mb-2 text-sm font-medium text-[#2F3545]">Password</label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter your password"
              className="border border-lightgray p-3 rounded-md focus:outline-none"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-10 text-sm text-gray-500"
            >
              {showPassword ? "Hide" : "Show"}
            </button>
          </div>
          <button
            type="submit"
            className="w-full bg-[#2F3545] text-white py-3 rounded-md hover:bg-[#3a424c]"
            disabled={isLoading}
          >
            {isLoading ? 'Logging in...' : 'Login'}
          </button>
        </form>
      </div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
    </div>
  );
};

export default Login;