import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import Quill's default theme
import { contactbg } from '../../Assets';

const CreatePublication = () => {
  const [formData, setFormData] = useState({
    title: '',
    content: '', // Initially empty content
    image: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, image: file });
    setPreviewImage(URL.createObjectURL(file)); // Generate a preview URL for the selected image
  };

  const handleContentChange = (value) => {
    setFormData({ ...formData, content: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const publicationData = new FormData();
    publicationData.append('title', formData.title);
    publicationData.append('content', formData.content); // Use rich-text content
    publicationData.append('datePublished', new Date().toISOString().split('T')[0]);
    if (formData.image) publicationData.append('image', formData.image);

    try {
      const response = await fetch('/api/create_publication.php', {
        method: 'POST',
        body: publicationData,
      });

      if (response.ok) {
        toast.success('Publication created successfully!');
        setFormData({ title: '', content: '', image: null });
        setPreviewImage(null);
        navigate('/dashboard'); // Redirect to dashboard
      } else {
        toast.error('Error creating publication.');
      }
    } catch (error) {
      toast.error('Error submitting the publication.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="relative bg-cover bg-top min-h-screen pt-24 flex items-center justify-center"
      style={{ backgroundImage: `url(${contactbg})` }}
    >
      <div className="relative z-10 w-full max-w-3xl bg-white shadow-lg rounded-lg p-8 m-8">
        <h1 className="text-3xl font-bold text-center text-[#2F3545] mb-2">Create Publication</h1>
        <div className="text-center mb-6">
          <Link to="/dashboard" className="text-[#2F3545] hover:underline">
            ⏮️ Back to Dashboard
          </Link>
        </div>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="mb-4">
            <label htmlFor="title" className="text-sm font-medium text-[#2F3545]">Title</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="w-full border border-gray-300 p-3 rounded-md focus:outline-none"
              placeholder="Enter the title"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="content" className="text-sm font-medium text-[#2F3545]">Content</label>
            <ReactQuill
              value={formData.content}
              onChange={handleContentChange} // Handle rich-text content changes
              className="w-full border border-gray-300 p-3 rounded-md focus:outline-none"
              placeholder="Enter the content"
              theme="snow"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="image" className="text-sm font-medium text-[#2F3545]">Image</label>
            <input
              type="file"
              id="image"
              name="image"
              onChange={handleImageChange}
              className="w-full border border-gray-300 p-3 rounded-md focus:outline-none"
              accept="image/*"
            />
            {previewImage && (
              <div className="mt-4">
                <img src={previewImage} alt="Preview" className="w-full h-auto rounded-md shadow-md mb-2" />
                <button
                  type="button"
                  onClick={() => {
                    setFormData({ ...formData, image: null });
                    setPreviewImage(null);
                  }}
                  className="text-sm text-red-500 hover:underline"
                >
                  Remove Image
                </button>
              </div>
            )}
          </div>
          <button
            type="submit"
            className="w-full bg-[#2F3545] text-white py-3 rounded-md hover:bg-[#3a424c]"
            disabled={isLoading}
          >
            {isLoading ? 'Creating...' : 'Create Publication'}
          </button>
        </form>
      </div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
    </div>
  );
};

export default CreatePublication;