import React, { useState, useEffect } from 'react';
import { logo } from '../Assets';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate for redirection

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State for login status
  const location = useLocation(); // Get current location (URL)
  const navigate = useNavigate(); // For redirecting after logout

  useEffect(() => {
    // Check login status from localStorage on component mount
    const loggedInStatus = localStorage.getItem('isLoggedIn');
    setIsLoggedIn(loggedInStatus === 'true');
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  // Menu items array
  const menuItems = [
    { name: 'Home', path: '/' },
    { name: 'About Us', path: '/about' },
    { name: 'Practice Area', path: '/services' },
    { name: 'Publications', path: '/publications' },
  ];

  // Function to check if the current route is active
  const isActive = (path) => location.pathname === path;

  // Logout functionality
  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn'); // Clear login status
    setIsLoggedIn(false); // Update state
    navigate('/login'); // Redirect to login page
  };

  return (
    <nav className="fixed top-0 left-0 w-full bg-[#ECE3D8] border-b-2 border-gray-200 z-50">
      <div className="container mx-auto px-4 py-3 flex justify-between items-center">
        {/* Logo */}
        <div className="text-2xl font-semibold">
          <a href="/">
            <img src={logo} alt="Logo" />
          </a>
        </div>

        {/* Menu - hidden on mobile, visible on larger screens */}
        <div className="hidden md:flex space-x-6">
          {menuItems.map((item) => (
            <a
              key={item.path}
              href={item.path}
              className={`${
                isActive(item.path)
                  ? 'bg-[#2F3545] text-[#C1A17C] shadow-none '
                  : 'text-[#2F3545] hover:bg-[#272C3A] hover:shadow-md shadow-black hover:text-white'
              } px-4 py-2 rounded-md`}
            >
              {item.name}
            </a>
          ))}
        </div>

        {/* Buttons (Contact Us & Logout) */}
        <div className="hidden md:flex space-x-4">
          <a
            href="/contact"
            className={`${
              isActive('/contact')
                ? 'bg-[#F8F3E0] text-[#2F3545] border-2 border-[#2F3545]'
                : 'bg-[#C1A17C] text-[#2F3545] border-2 border-gray-200'
            } px-4 py-2 rounded-md hover:bg-[#61513E] hover:text-white transition-all duration-300`}
          >
            Contact Us
          </a>
          {isLoggedIn && (
            <button
              onClick={handleLogout}
              className="bg-[#C1A17C] text-[#2F3545] border-2 border-gray-200 px-4 py-2 rounded-md hover:bg-[#61513E] hover:text-white transition-all duration-300"
            >
              Logout
            </button>
          )}
        </div>

        {/* Mobile menu button */}
        <div className="md:hidden">
          <button onClick={toggleMobileMenu} className="focus:outline-none">
            <svg
              className="w-6 h-6 text-[#1e1e1e]"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile menu - only shown when isMobileMenuOpen is true */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-[#C1A17C]/30 px-4 py-2">
          {menuItems.map((item) => (
            <a
              key={item.path}
              href={item.path}
              className={`${
                isActive(item.path)
                  ? 'bg-[#2F3545] text-[#C1A17C]'
                  : 'text-[#2F3545] hover:bg-[#2F3545] hover:text-[#C1A17C]'
              } block px-4 py-2 rounded-md`}
            >
              {item.name}
            </a>
          ))}
          <a
            href="/contact"
            className={`block ${
              isActive('/contact')
                ? 'bg-[#F8F3E0] text-[#2F3545] border-2 border-[#2F3545]'
                : 'bg-[#C1A17C] text-[#2F3545] border-2 border-gray-200'
            } px-4 py-2 rounded-md hover:bg-[#61513E] hover:text-white transition-all duration-300`}
          >
            Contact Us
          </a>
          {isLoggedIn && (
            <button
              onClick={handleLogout}
              className="bg-[#C1A17C] text-[#2F3545] border-2 border-gray-200 px-4 py-2 rounded-md hover:bg-[#61513E] hover:text-white transition-all duration-300 mt-2"
            >
              Logout
            </button>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;