import React, { useState, useEffect } from "react";
import { publicationbg } from "../Assets"; // Import your hero image
import { Link } from "react-router-dom";

const PublicationsPage = () => {
  // State to hold the publications data
  const [publications, setPublications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch publications from the database
  useEffect(() => {
    const fetchPublications = async () => {
      try {
        // Prepare the URL to fetch publications (you can pass 'id' in the URL if required)
        const id = new URLSearchParams(window.location.search).get("id"); // Check if 'id' is in the URL
        let url = "/api/get_publications.php"; // Default endpoint

        // If an 'id' is provided, append it to the URL
        if (id) {
          url += `?id=${id}`;
        }

        // Send GET request to the PHP script
        const response = await fetch(url);

        const data = await response.json();

        // If the data is successfully fetched, set it to state
        if (data.data) {
          setPublications(data.data); // Use 'data' from the PHP response
        } else if (data.error) {
          console.error("Error fetching publications:", data.error);
        } else {
          console.error("Unexpected response format");
        }
      } catch (error) {
        console.error("Error fetching publications:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPublications();
  }, []);

  // Format the date to "Month Day, Year" (e.g., January 1, 2020)
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="pb-24">
      {" "}
      {/* Removed padding to make the hero image full-width */}
      {/* Hero Section */}
      <div
        className="relative bg-cover bg-center h-96 w-full flex items-center justify-center text-center mb-12"
        style={{ backgroundImage: `url(${publicationbg})` }}
      >
        <h1 className="text-4xl font-bold text-[#2F3545]">Publications</h1>
      </div>
      {/* Publications Content with Padding */}
      <div className="px-8 md:px-16 lg:px-32">
        {/* Publications Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {publications.map((publication, index) => (
            <div
              key={index}
              className="bg-white rounded-lg border border-[#C1A17C] overflow-hidden shadow hover:shadow-lg transition-shadow duration-300"
            >
              <img
                src={`/api/${publication.image}`} // Assuming image URL is relative from the server
                alt={publication.title}
                className="w-full h-48 object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h3 className="text-xl font-semibold text-[#2F3545] mb-2">
                  {publication.title}
                </h3>
                {/* Replaced shortText with content */}
                <div
                  className="text-sm text-gray-700 mb-4"
                  dangerouslySetInnerHTML={{
                    __html: publication.content.substring(0, 100),
                  }} // Render HTML content safely
                />
                <div className="flex items-center justify-between">
                  <Link
                    to={`/publication/${publication.id}`}
                    className="bg-[#C1A17C] text-[#2F3545] font-semibold py-2 px-4 rounded-md hover:bg-opacity-90"
                  >
                    Read More
                  </Link>
                  <span className="text-xs text-gray-500">
                    {formatDate(publication.datePublished)}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PublicationsPage;