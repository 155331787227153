import React from "react";
import {
  bannerbg,
  centeredbg,
  client1,
  client2,
  client3,
  client4,
  client5,
  client6,
  expertisebg,
  herobg,
  recordbg,
} from "../Assets";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll"; // Import react-scroll Link

const Home = () => {
  return (
    <div>
      {/* Hero Section */}
      <section
        className="h-screen bg-[#2F3545] flex flex-col justify-center px-8 lg:px-32 text-left text-white relative"
        style={{
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.6)), url(${herobg})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
        }}
      >
        <div className="lg:w-1/2">
          <h1 className="text-5xl md:text-7xl text-[#2F3545] font-bold mb-4">
            LaPeritum
          </h1>
          <p className="text-lg md:text-xl text-[#2F3545] mb-8">
            We provide legal and corporate business advisory services to our
            clients in a professional, timely manner and at competitive rates.
          </p>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <Link to="/contact">
              <button className="bg-[#C1A17C] text-[#2F3545] px-6 py-3 rounded-md border-2 border-gray-400">
                Book a Consultation
              </button>
            </Link>

            {/* Link to services page */}
            <Link to="/services">
              <button className="bg-[#2F3545] text-white px-6 py-3 rounded-md border-2 border-gray-700">
                Practice Areas
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* Banner Section */}
      <section
        className="text-center py-40 text-white relative"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bannerbg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <h2 className="text-3xl font-semibold italic">
          “Delivering Excellence <br /> in Legal Solutions”
        </h2>
      </section>

      {/* Why Choose Us Section */}
      <section className="py-16 px-8 md:px-16">
        <h2 className="text-4xl md:text-5xl font-bold text-center text-[#2F3545] mb-4">
          Why Choose Us?
        </h2>
        <p className="text-center text-lg text-[#C1A17C] mb-12 max-w-xl mx-auto">
          We know you have a choice. Thank you for choosing us.
        </p>

        {/* Expertise Across Industries */}
        <div className="md:flex mb-12 items-center">
          <div className="md:w-1/2 lg:w-2/5 mb-8 md:mb-0 md:pr-8 text-center md:text-left max-w-md mx-auto md:max-w-none">
            <h3 className="text-2xl md:text-3xl font-semibold text-[#C1A17C] mb-4">
              Expertise Across Industries
            </h3>
            <p className="text-center md:text-left mb-6 max-w-sm mx-auto md:max-w-none">
              La Peritum Law Practice brings in-depth expertise across diverse
              sectors, ensuring comprehensive legal support tailored to each
              industry, providing you with a competitive edge.
            </p>
            <Link to="/services">
              <button className="bg-[#2F3545] text-white px-6 py-2 rounded-md">
                Explore Our Practice Areas
              </button>
            </Link>{" "}
          </div>
          <div className="md:w-1/2 flex justify-center">
            <img
              src={expertisebg}
              alt="Expertise"
              className="rounded-lg w-3/4 object-cover"
            />
          </div>
        </div>

        {/* Client-Centered Approach */}
        <div className="md:flex mb-12 flex-row-reverse items-center">
          <div className="md:w-1/2 lg:w-2/5 mb-8 md:mb-0 md:pl-8 text-center md:text-left max-w-md mx-auto md:max-w-none">
            <h3 className="text-2xl md:text-3xl font-semibold text-[#C1A17C] mb-4">
              Client-Centered Approach
            </h3>
            <p className="text-center md:text-left mb-6 max-w-sm mx-auto md:max-w-none">
              Your goals are our priority. We tailor every solution to meet your
              specific needs, ensuring dedicated support every step of the way.
            </p>
            <Link to="/contact">
              <button className="bg-[#2F3545] text-white px-6 py-2 rounded-md">
                Schedule Your Consultation
              </button>
            </Link>
          </div>
          <div className="md:w-1/2 flex justify-center">
            <img
              src={centeredbg}
              alt="Client-Centered"
              className="rounded-lg w-3/4 object-cover"
            />
          </div>
        </div>

        {/* Proven Track Record */}
        <div className="md:flex items-center">
          <div className="md:w-1/2 lg:w-2/5 mb-8 md:mb-0 md:pr-8 text-center md:text-left max-w-md mx-auto md:max-w-none">
            <h3 className="text-2xl md:text-3xl font-semibold text-[#C1A17C] mb-4">
              Proven Track Record
            </h3>
            <p className="text-center md:text-left mb-6 max-w-sm mx-auto md:max-w-none">
              Our consistent success in dispute resolutions, transaction
              management, and intellectual property protection speaks to our
              commitment to results.
            </p>
            <ScrollLink to="testimonials" smooth={true} duration={500}>
              <button className="bg-[#2F3545] text-white px-6 py-2 rounded-md">
                Explore Our Success Stories
              </button>
            </ScrollLink>
          </div>
          <div className="md:w-1/2 flex justify-center">
            <img
              src={recordbg}
              alt="Proven Track Record"
              className="rounded-lg w-3/4 object-cover"
            />
          </div>
        </div>
      </section>

      {/* Clients Section */}
      <section className="bg-[#2F3545] py-16 text-center text-white">
        <h2 className="text-4xl font-bold mb-8">Trusted Business Partners</h2>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-8 max-w-4xl mx-auto px-4">
          {" "}
          {/* Added padding on mobile */}
          {/* Client logos inside cream-colored boxes with full image fill */}
          <div className="bg-[#F4EDE3] p-6 rounded-lg flex justify-center items-center">
            <img
              src={client1}
              alt="Client 1"
              className="w-full h-32 object-contain"
            />
          </div>
          <div className="bg-[#F4EDE3] p-6 rounded-lg flex justify-center items-center">
            <img
              src={client2}
              alt="Client 2"
              className="w-full h-32 object-contain"
            />
          </div>
          <div className="bg-[#F4EDE3] p-6 rounded-lg flex justify-center items-center">
            <img
              src={client3}
              alt="Client 3"
              className="w-full h-32 object-contain"
            />
          </div>
          <div className="bg-[#F4EDE3] p-6 rounded-lg flex justify-center items-center">
            <img
              src={client4}
              alt="Client 4"
              className="w-full h-32 object-contain"
            />
          </div>
          <div className="bg-[#F4EDE3] p-6 rounded-lg flex justify-center items-center">
            <img
              src={client5}
              alt="Client 5"
              className="w-full h-32 object-contain"
            />
          </div>
          <div className="bg-[#F4EDE3] p-6 rounded-lg flex justify-center items-center">
            <img
              src={client6}
              alt="Client 6"
              className="w-full h-32 object-contain"
            />
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-16 px-8 md:px-16" id="testimonials">
        <h2 className="text-4xl font-bold text-center mb-4">Testimonials</h2>
        <p className="text-center text-lg mb-12">What they say about us</p>

        <div className="grid md:grid-cols-3 gap-8">
          {/* Testimonial 1 */}
          <div className="rounded-lg border border-gray-300 p-6 text-center transition-shadow duration-300 hover:shadow-lg hover:shadow-gray-400/30">
            <img
              src={client3}
              alt="Client 1"
              className="w-16 rounded-full mx-auto mb-4"
            />
            <p className="mb-4">
              "Navigating the regulatory requirements in the Nigerian energy
              sector can be challenging, but La Peritum Law Practice made it
              seamless. Their energy law expertise and proactive approach helped
              us secure vital contracts and compliance approvals. Their team
              truly understands the industry."
            </p>
            <p className="text-sm text-gray-600">
              Managing Director, Baba Energy Nigeria Ltd.
            </p>
          </div>

          {/* Testimonial 2 */}
          <div className="rounded-lg border border-gray-300 p-6 text-center transition-shadow duration-300 hover:shadow-lg hover:shadow-gray-400/30">
            <img
              src={client1}
              alt="Client 2"
              className="w-40 rounded-full mx-auto mb-4 bg-[#2F3545]" 
            />
            <p className="mb-4">
              "Partnering with La Peritum Law Practice for our company
              secretarial needs has been a great decision. Their expertise in
              corporate governance and attention to detail have ensured our
              regulatory compliance. With their timely and proactive support, we
              can focus on growing our business with peace of mind."
            </p>
            <p className="text-sm text-gray-600">
              Chairman, SAGLEV Electromobility
            </p>
          </div>

          {/* Testimonial 3 */}
          <div className="rounded-lg border border-gray-300 p-6 text-center transition-shadow duration-300 hover:shadow-lg hover:shadow-gray-400/30">
            <img
              src={client6}
              alt="Client 3"
              className="w-16 rounded-full mx-auto mb-4"
            />
            <p className="mb-4">
              "Obtaining a Money Lender’s Licence in Nigeria can be complex, but
              La Peritum Law Practice made it effortless. Their expertise,
              prompt guidance, and efficient handling of the application and
              regulatory processes ensured a smooth experience. We highly
              recommend their services to businesses navigating this process."
            </p>
            <p className="text-sm text-gray-600">
              Managing Director, SCL Nigeria Limited
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
