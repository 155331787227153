import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import Quill's stylesheet
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { contactbg } from '../../Assets';

const EditPublication = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const id = queryParams.get('id'); // Extract ID from query params

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    image: null,
    existingImage: null, // Store the existing image name
  });
  const [previewImage, setPreviewImage] = useState(null); // For displaying image preview
  const [isLoading, setIsLoading] = useState(false);

  // Fetch existing publication data on component load
  useEffect(() => {
    console.log('Component mounted, fetching publication data...');
    if (!id) {
      toast.error('Invalid publication ID');
      return;
    }

    const fetchPublication = async () => {
      try {
        console.log(`Fetching publication data for ID: ${id}`);
        const response = await fetch(`/api/get_publications.php?id=${id}`);
        const result = await response.json(); // `result` is the whole response object, including the `data` array
        console.log('Fetched result:', result);
    
        if (result.data && Array.isArray(result.data) && result.data.length > 0) {
          const publication = result.data[0]; // Access the first publication in the array
          console.log('Publication data:', publication);
    
          setFormData({
            title: publication.title || '',
            content: publication.content || '',
            existingImage: publication.image || null, // Set the existing image
          });
    
          if (publication.image) {
            const image = `/api/${publication.image}`; // Assuming images are stored in 'uploads' folder
            setPreviewImage(image);
          }
        } else {
          toast.error('Publication not found');
        }
      } catch (error) {
        toast.error('Error fetching publication.');
        console.error('Error fetching publication:', error);
      } finally {
        setIsLoading(false);
        console.log('Finished fetching publication data');
      }
    };

    fetchPublication();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`Input changed - Name: ${name}, Value: ${value}`);
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    console.log('Image file selected:', file);
    setFormData({ ...formData, image: file });
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleContentChange = (value) => {
    console.log('Content changed:', value);
    setFormData({ ...formData, content: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted, updating publication...');
    setIsLoading(true);

    const publicationData = new FormData();
    publicationData.append('title', formData.title);
    publicationData.append('content', formData.content);
    publicationData.append('datePublished', new Date().toISOString().split('T')[0]); // Assuming current date
    publicationData.append('id', id);

    if (formData.image) {
      publicationData.append('image', formData.image);
    } else {
      publicationData.append('existingImage', formData.existingImage); // Send existing image if no new image is selected
    }

    try {
      const response = await fetch('/api/update_publication.php', {
        method: 'POST',
        body: publicationData,
      });

      if (response.ok) {
        toast.success('Publication updated successfully!');
        console.log('Publication updated successfully');
        navigate('/dashboard');
      } else {
        toast.error('Error updating publication.');
        console.error('Error updating publication:', response.statusText);
      }
    } catch (error) {
      toast.error('Error updating publication.');
      console.error('Error during submission:', error);
    } finally {
      setIsLoading(false);
      console.log('Form submission complete');
    }
  };

  return (
    <div
      className="relative bg-cover bg-top min-h-screen pt-24 flex items-center justify-center"
      style={{ backgroundImage: `url(${contactbg})` }}
    >
      <div className="relative z-10 w-full max-w-3xl bg-white shadow-lg rounded-lg p-8 m-8">
        <h1 className="text-3xl font-bold text-center text-[#2F3545] mb-2">Edit Publication</h1>
        <div className="text-center mb-6">
          <Link to="/dashboard" className="text-[#2F3545] hover:underline">
            ⏮️ Back to Dashboard
          </Link>
        </div>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="mb-4">
            <label htmlFor="title" className="text-sm font-medium text-[#2F3545]">Title</label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="w-full border border-gray-300 p-3 rounded-md focus:outline-none"
              placeholder="Enter the title"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="content" className="text-sm font-medium text-[#2F3545]">Content</label>
            <ReactQuill
              value={formData.content}
              onChange={handleContentChange} // Handle rich text input
              className="w-full border border-gray-300 p-3 rounded-md focus:outline-none"
              placeholder="Enter the content"
              theme="snow"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="image" className="text-sm font-medium text-[#2F3545]">Image</label>
            <input
              type="file"
              id="image"
              name="image"
              onChange={handleImageChange}
              className="w-full border border-gray-300 p-3 rounded-md focus:outline-none"
              accept="image/*"
            />
            {previewImage && (
              <div className="mt-4">
                <img
                  src={previewImage}
                  alt="Preview"
                  className="w-full h-auto rounded-md shadow-md mb-2"
                />
                <button
                  type="button"
                  onClick={() => {
                    setFormData({ ...formData, image: null });
                    setPreviewImage(null);
                  }}
                  className="text-sm text-red-500 hover:underline"
                >
                  Remove Image
                </button>
              </div>
            )}
            {!previewImage && formData.existingImage && (
              <div className="mt-4">
                <img
                  src={`/api/uploads/${formData.existingImage}`} // Display the existing image
                  alt="Existing"
                  className="w-full h-auto rounded-md shadow-md mb-2"
                />
              </div>
            )}
          </div>
          <button
            type="submit"
            className="w-full bg-[#2F3545] text-white py-3 rounded-md hover:bg-[#3a424c]"
            disabled={isLoading}
          >
            {isLoading ? 'Updating...' : 'Update Publication'}
          </button>
        </form>
      </div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
    </div>
  );
};

export default EditPublication;