import React, { useState } from 'react';
import { contactbg } from '../Assets';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA component

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [recaptchaValue, setRecaptchaValue] = useState(null); // Store reCAPTCHA response

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value); // Store reCAPTCHA response value
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!recaptchaValue) {
      toast.error('Please verify that you are not a robot.'); // Error if reCAPTCHA is not filled
      return;
    }

    setIsLoading(true); // Set loading to true on submit

    try {
      const response = await fetch('/api/contact-form.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          recaptcha: recaptchaValue, // Send the reCAPTCHA response along with form data
        }),
      });

      const result = await response.json();
      if (response.ok) {
        toast.success(result.message); // Show success toast
        setFormData({ // Reset form
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: ''
        });
      } else {
        toast.error(result.message); // Show error toast
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      toast.error('There was a problem submitting the form.'); // Show error toast
    } finally {
      setIsLoading(false); // Set loading to false after submission
    }
  };

  return (
    <div
      className="relative bg-cover bg-top min-h-screen pt-32 pb-24"
      style={{ backgroundImage: `url(${contactbg})` }}
    >
      <div className="relative z-10 flex justify-center items-center text-[#2F3545] h-full px-8">
        <div className="w-full max-w-6xl">
          {/* Left Column: Text */}
          <div className="w-full md:w-1/2 mb-8">
            <h1 className="text-4xl font-bold mb-4">Feel free to write to us</h1>
            <p className="text-lg mb-6">
              Your feedback is important to us. Kindly fill out the form, and we’ll reach out to you at our earliest convenience.
            </p>
          </div>

          {/* Right Column: Form */}
          <div className="w-full md:w-2/5 bg-white shadow-lg rounded-lg p-8">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col sm:flex-row sm:space-x-4 mb-4">
                <div className="flex flex-col w-full sm:w-1/2 mb-4 sm:mb-0">
                  <label htmlFor="firstName" className="mb-2 text-sm font-medium text-[#2F3545]">First Name</label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder="John"
                    className="border border-lightgray p-3 rounded-md focus:outline-none"
                  />
                </div>
                <div className="flex flex-col w-full sm:w-1/2 mb-4 sm:mb-0">
                  <label htmlFor="lastName" className="mb-2 text-sm font-medium text-[#2F3545]">Last Name</label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder="Doe"
                    className="border border-lightgray p-3 rounded-md focus:outline-none"
                  />
                </div>
              </div>

              <div className="flex flex-col mb-4">
                <label htmlFor="email" className="mb-2 text-sm font-medium text-[#2F3545]">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="john.doe@example.com"
                  className="border border-lightgray p-3 rounded-md focus:outline-none"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label htmlFor="phone" className="mb-2 text-sm font-medium text-[#2F3545]">Phone Number</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="+123456789"
                  className="border border-lightgray p-3 rounded-md focus:outline-none"
                />
              </div>
              <div className="flex flex-col mb-6">
                <label htmlFor="message" className="mb-2 text-sm font-medium text-[#2F3545]">Message</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Write your message here..."
                  className="border border-lightgray p-3 rounded-md focus:outline-none"
                  rows="4"
                />
              </div>

              {/* Google reCAPTCHA */}
              <div className="mb-6">
                <ReCAPTCHA
                  sitekey="6Ldai7wUAAAAAEOonqpjKm8AaN9GBIL8DC5nmG9e" // Your reCAPTCHA v2 site key
                  onChange={handleRecaptchaChange} // Handle reCAPTCHA response
                />
              </div>

              <button
                type="submit"
                className="w-full bg-[#2F3545] text-white py-3 rounded-md hover:bg-[#3a424c]"
                disabled={isLoading} // Disable button during loading
              >
                {isLoading ? 'Sending...' : 'Send Message'} {/* Button text changes */}
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar />
    </div>
  );
};

export default Contact;